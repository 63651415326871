import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

import { ContextIdDto, Notification } from '@savvy/notification';
import {Customer, CustomerService} from "@savvy/customer";
import {ConfirmationDialogComponent} from "../../confirmation-dialog/confirmation-dialog.component";
import {FloSnackbarComponent} from "../../snackbar/floSnackbar.component";
import {MatDialog} from "@angular/material/dialog";
import {UpdateCustomer} from "@savvy/customer";
import {PetService} from "@savvy/pet";

@Component({
  selector: 'app-onboarding-notification',
  templateUrl: './onboardingNotification.component.html'
})
export class OnboardingNotificationComponent implements OnInit {

  @Input() notification: Notification;
  @Input() contextIdDto: ContextIdDto;

  @Output() notificationProcessed: EventEmitter<any> = new EventEmitter();

  customer: Customer;

  constructor(private notify: FloSnackbarComponent,
              private customerService: CustomerService,
              private petService: PetService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.loadCustomer();
  }


  loadCustomer() {
    this.customerService.loadCustomer(this.notification.customerId, this.contextIdDto.contextId,
      this.contextIdDto.contextIdType).subscribe(response => {
        this.customer = response;
    })
  }




  archive() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Archive',
        message: `Are you sure you want to archive this customer ${this.customer.firstName} ${this.customer.lastName}?`
      },
      height: 'auto',
      width: '360px',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.customerService.deleteCustomer(this.contextIdDto.contextId,
          this.contextIdDto.contextIdType,
          this.customer.id
        ).subscribe(response => {
          this.notify.message = 'Archived Customer';
          this.notify.open();

          this.loadCustomer();

          // Client can delete notification if they want? or should we?
          this.notificationProcessed.emit('done');
        });
      }
    });
  }


  verify() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Verify',
        message: `Are you sure you want to verify this customer ${this.customer.firstName} ${this.customer.lastName}?`
      },
      height: 'auto',
      width: '360px',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.customer.approvalNeeded = false;
        const updateCustomer: UpdateCustomer = {
          contextIdDto: this.contextIdDto,
          customer: this.customer
        };
        this.customerService.updateCustomer(updateCustomer).subscribe(response => {
          this.verifyPets(response?.customerId?.id);
          this.notify.message = 'Customer verified';
          this.notify.open();

          this.customer = response;
          // Client can delete notification if they want? or should we?
          this.notificationProcessed.emit('done');
        });
      }
    });
  }


  // THIS SHOULD BE DONE SERVER SIDE ONBOARD.verify(customerId);
  verifyPets(customerId: string) {
    if (customerId) {
      this.petService.loadCustomersPets(
        customerId).subscribe(pets => {
        if (pets && pets?.length) {
          for (const pet of pets) {
            pet.approvalNeeded = false;
            this.petService.updatePet(pet).subscribe(res => {
              console.log('Pet approved', res);
            });
          }
        }
      });
    }
  }





 /* verifyCustomers() {
    sessionStorage.setItem('selectedMenuItemType', 'CUSTOMER_ONBOARDING');
    sessionStorage.setItem('expandedIndex', String(5));
    this.router.navigate(['admin/settings', this.contextIdDto.contextId, this.contextIdDto.contextIdType, 'on-boarding']);
    setTimeout(() => {
      location.reload();
    }, 10);
  }*/

  /*

  verifyCustomers() {
    sessionStorage.setItem('selectedMenuItemType', 'CUSTOMER_ONBOARDING');

    // We need a PROPER route to verify customers imo
    // I think this only works if we are in the correct admin menu first!!!
     sessionStorage.setItem('expandedIndex', String(5));
    this.router.navigate(['admin/settings', this.contextIdDto.contextId, this.contextIdDto.contextIdType, 'on-boarding']);
    setTimeout(() => {
       location.reload();
     }, 10);
  }
   */

}
