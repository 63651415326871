<div *ngIf="invoice" class="container view-invoice-page mdc-p-3" fxLayout="column" fxLayoutAlign="center">
  <div fxLayout="row" fxLayoutWrap="wrap" class="mdc-mb-1">
    <div fxFlex="auto">
      <h3 class="mb-0"><strong>{{'EDIT_INVOICE' | translate: {Default: 'Edit Invoice'} }}</strong></h3>
      <p>{{'INVOICE_NO' | translate: {Default: 'Invoice Number'} }}: <strong>{{invoice.invoiceNumber}}</strong></p>
      <p>{{'INVOICE_DATE' | translate: {Default: 'Invoice Date'} }}: <strong>{{invoice.invoiceDate |
          localizedDate:'short'}}</strong></p>
      <a href="javascript:void(0)" *ngIf="invoice.companyDetails">{{invoice.companyDetails.companyEmail}}</a>
    </div>
  </div>

  <form #invoiceForm="ngForm">
    <div fxLayout="row" fxLayoutGap="16px">
      <!-- <mat-form-field fxFlex appearance="outline" class="w-100">
        <mat-label>{{'Invoice Number' | translate}}</mat-label>
        <input matInput [(ngModel)]="invoice.invoiceNumber" name="invoiceNumber" #name="ngModel" id="invoiceNumber"
          placeholder="{{'Invoice Number' | translate}}" required disabled>
      </mat-form-field> -->
      <!-- <mat-form-field fxFlex appearance="outline" class="w-100">
        <mat-label>{{'Invoice Date' | translate}}</mat-label>
        <input matInput [(ngModel)]="invoice.invoiceDate" name="invoiceDate" (focus)="picker.open()" and
          (click)="picker.open()" #name="ngModel" [matDatepicker]="picker" placeholder="{{'Invoice Date' | translate}}"
          required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field> -->
      <div fxFlex="100" fxFlex.gt-sm="30">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'DUE_DATE' | translate: {Default: 'Due Date'} }}</mat-label>
          <input matInput [(ngModel)]="invoice.dueDate" name="dueDate" (focus)="dueDatePicker.open()" and
            (click)="dueDatePicker.open()" #name="ngModel" [matDatepicker]="dueDatePicker"
            placeholder="{{'DUE_DATE' | translate: {Default: 'Due Date'} }}" required>
          <mat-datepicker-toggle matSuffix [for]="dueDatePicker" />
          <mat-datepicker #dueDatePicker />
        </mat-form-field>
      </div>
    </div>
  </form>


  <!-- <h4 class="mb-2">Products</h4> -->
  <div class="">
    <div fxLayout="row">
      <div fxFlexAlign="center">
        <h6 class="mb-0">{{'PRODUCTS' | translate: {Default: 'Products'} }}</h6>
      </div>
      <span class="example-spacer"></span>
      <button mat-button color="accent" type="button" (click)="createProductInvoiceRow()">
        <mat-icon>add</mat-icon>
        {{'ADD_PRODUCT' | translate: {Default: 'Add Product'} }}
      </button>
    </div>
    <div class="no-data-available" *ngIf="!invoice.invoiceProductRows || !invoice.invoiceProductRows.length">
      {{'NO_PRODUCTS_IN_THIS_INVOICE' | translate: {Default: 'There are no products in this invoice.'} }}
    </div>
    <table class="table-saperate" *ngIf="invoice.invoiceProductRows && invoice.invoiceProductRows.length">
      <thead>
        <tr>
          <th class="mat-text-secondary">{{'PRODUCT' | translate: {Default: 'Product'} }}</th>
          <th class="mat-text-secondary" style="max-width:10%">{{'UNIT_PRICE' | translate: {Default: 'Unit Price'} }}
          </th>
          <th class="mat-text-secondary" style="max-width:10%">{{'QUANTITY' | translate: {Default: 'Quantity'} }}</th>
          <th class="mat-text-secondary" *ngIf="!invoice?.paidInFull">{{'DISCOUNT' | translate: {Default: 'Discount'} }}
          </th>
          <th class="mat-text-secondary">{{'TAX' | translate: {Default: 'Tax'} }}</th>
          <th class="mat-text-secondary">{{'AMOUNT' | translate: {Default: 'Amount'} }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of invoice.invoiceProductRows">
          <td>
            <mat-form-field style="width: 100%">
              <mat-select placeholder="{{'SELECT_PRODUCT' | translate: {Default: 'Select Product'} }}"
                [(ngModel)]="row.productNumber" required (selectionChange)="productChanged(row)" name="product"
                #product="ngModel">
                <mat-option>
                  <ngx-mat-select-search [placeholderLabel]="'Search Product'" ngModel
                    (ngModelChange)="filterProducts($event)"
                    [noEntriesFoundLabel]="'NO_MATCHING_PRODUCT' | translate: {Default: 'No Matching Product'} " />
                </mat-option>
                <mat-option *ngFor="let product of products | productFilter: searchProduct" [value]="product.id">
                  {{ product.productName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="product.hasError('required')">
                {{'PRODUCT_IS' | translate: {Default: 'Product is'} }} <strong>{{'REQUIRED' | translate: {Default:
                  'required'} }}</strong>
              </mat-error>
            </mat-form-field>
            <div *ngIf="product.hasError('required')">
              {{'PRODUCT_IS' | translate: {Default: 'Product is'} }} <strong>{{'REQUIRED' | translate: {Default:
                'required'} }}</strong>
            </div>
          </td>
          <td>
            <input class="table-input w-100px" type="text" appNumberOnly [decimalPoints]="2"
              [placeholder]="'UNIT_PRICE' | translate: {Default: 'Unit Price'} " name="unitPrice" #unitPrice="ngModel"
              (change)="updateInvoice()" [(ngModel)]="row.unitPrice" id="unitPrice" required>
            <div *ngIf="unitPrice.hasError('required')">
              {{'UNIT_PRICE_IS' | translate: {Default: 'Unit Price is'} }} <strong>{{'REQUIRED' | translate: {Default:
                'required'} }}</strong>
            </div>
          </td>
          <td>
            <input class="table-input w-100px" type="text" appNumberOnly
              [placeholder]="'QUANTITY' | translate: {Default: 'Quantity'}" name="quantity" #quantity="ngModel"
              (change)="updateInvoice()" [(ngModel)]="row.quantity" id="quantity" required
              [disabled]="invoice?.paidInFull">
            <div *ngIf="quantity.hasError('required')">
              {{'QUANTITY_IS' | translate: {Default: 'Quantity is'} }} <strong>{{'REQUIRED' | translate: {Default:
                'required'} }}</strong>
            </div>
          </td>
          <td *ngIf="!invoice?.paidInFull">
            <button (click)="onDiscountClick(row)" class="ml-xs mat-icon-button" mat-icon-button=""
              [matTooltip]="row?.discountDetails | discountTmpl:currencyCode">
              {{row?.discountDetails | discountTmpl:currencyCode}}

              <mat-icon role="img" [ngClass]="{'discount-applied': row?.discountDetails}" class="demo-toolbar-icon"
                aria-label="Discount">local_offer</mat-icon>
            </button>
          </td>
          <td>
            {{row.tax || '0.00'}}%
          </td>
          <td>
            <app-discount-price-view [originalPrice]="row.grossPriceNoOffer" [finalPrice]="row.grossPrice"
              [currencyCode]="currencyCode" />
          </td>
          <td>
            <button mat-icon-button (click)="deleteProductRow(row)" *ngIf="!invoice?.paidInFull">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>


  <div fxLayout="row" fxLayoutWrap="wrap" class="mdc-mb-1">
    <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
      <h6 class="text-uppercase text-md ma-0 text-bold">{{'SUBTOTAL' | translate: {Default: 'Subtotal'} }}</h6>
      <h5 class="ma-0">{{invoice.subTotal | currency:currencyCode:'symbol'}}</h5>
    </div>
    <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
      <h6 class="text-uppercase text-md ma-0 text-bold">{{'TAX' | translate: {Default: 'Tax'} }}</h6>
      <h5 class="ma-0">{{invoice.tax | currency:currencyCode:'symbol'}}</h5>
    </div>
    <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1">
      <h6 class="text-uppercase text-md ma-0 text-bold">{{'DISCOUNT' | translate: {Default: 'Discount'} }}</h6>
      <h5 class="ma-0">{{0.00|currency:currencyCode:'symbol'}}</h5>
    </div>
    <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" class="mt-1 mdc-mb-1 text-xs-left text-sm-right">
      <h6 class="text-uppercase text-sm ma-0 text-bold">{{'TOTAL' | translate: {Default: 'Total'} }}</h6>
      <h5 class="ma-0 mat-text-green">{{ invoice.total | currency:currencyCode:'symbol'}}</h5>
    </div>
  </div>

  <p class="text-md mdc-mb-1" *ngIf="invoice?.invoiceTerms">
    <strong>
      {{'PAYMENT_TERMS_AND_POLICIES' | translate: {Default: 'Payment Terms and Policies'} }}
    </strong>
    {{invoice.invoiceTerms}}
  </p>


  <!-- <button mat-raised-button color="accent" *ngIf="!invoice.id" (click)="addInvoice()"
    [disabled]="!invoiceForm.form.valid" disabled="disabled">{{'Create Invoice' | translate}}
  </button>

  &nbsp; -->
  <div fxLayout="row" fxLayoutWrap="wrap">
    <div fxFlex></div>
    <div fxFlex="nogrow">
      <button mat-raised-button color="accent" *ngIf="invoice.id" (click)="updateInvoice(true)"
        [disabled]="!invoiceForm.form.valid" disabled="disabled">{{'UPDATE_INVOICE' | translate: {Default: 'Update
        Invoice'} }}
      </button>
    </div>
  </div>


</div>