import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ContextIdDto } from '@savvy/quickbooks';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  Payment,
  PaymentcompService,
  PaymentSettings,
  PaymentSettingsService,
  RecordPaymentComp
} from '@savvy/payment';
import { Location } from '@angular/common';
import {
  InvoicecompService,
  InvoiceDefinition,
  InvoiceDto,
  InvoicePaymentStatusDto,
  ViewInvoicePaymentStatusDataDto
} from '@savvy/invoice';
import { DiscountItem, DiscountSettings, DiscountSettingsService, Invoice, InvoiceService, UpdateInvoice } from '@savvy/invoice';
import { GLOBAL } from 'src/app/app.constants';
import { FloSnackbarComponent } from '../../snackbar/floSnackbar.component';
import { UrlLookupService } from '../../shared/services/urlLookup.service';
import { EventBusService } from '../../element/EventBusService';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { SendInvoiceDialogComponent } from '../../invoice/sendInvoiceDialog.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AddPaymentModalComponent } from '../../shared/components/add-payment-modal/add-payment-modal.component';
import { CaptureTokenizedPaymentComp, CardMachine, PaymentsResponse, SavvyPayCardMachineService, SavvyPayPaymentsCompService } from '@savvy/savvy-pay';
import { PlanDto, PlansCompService } from '@savvy/plan';
import { RequestForPayment } from '@savvy/request-for-payment';

import PaymentMethodEnum = Payment.PaymentMethodEnum;
import { CustomerCompositeService, CustomerService } from '@savvy/customer';
import { CardMachinePaymentComponent } from '../../invoice/card-machine-payment.component';
import { SmsLinkPaymentComponent } from '../../invoice/sms-link-payment.component';
import { PlansPaymentComponent } from '../../invoice/plans-payment.component';
import { Customer } from '@savvy/entity-instance-composite';
import { EditInvoiceV2Component } from '../edit-invoice-v2/edit-invoice-v2.component';
import * as _ from 'lodash';
import { UserCurrencyService } from '../../shared/services/userCurrency.service';
import {OrgCompositeService, OrgDto} from "@savvy/org";

class ModalData {
  invoiceId: string;
  contextIdDto: ContextIdDto;
  viewOnly?: boolean;
  postalCharges?: number;
}
@Component({
  selector: 'app-view-invoice-v2',
  templateUrl: './view-invoice-v2.component.html',
  styleUrls: ['./view-invoice-v2.component.scss']
})
export class ViewInvoiceV2Component implements OnInit, OnDestroy, OnChanges {

  @Input() eventBus: EventBusService;
  contextIdDto: ContextIdDto;

  invoiceId: string;
  invoiceDto: InvoiceDto = {} as InvoiceDto;
  invoice: Invoice = {} as Invoice;
  show: string;
  destroy$ = new Subject();
  model: Payment;
  isPaidFull = false;
  currencyCode: string;


  discountSettings: DiscountSettings;
  discountItemsById: { [key: string]: DiscountItem } = {};

  cardMachines: Array<CardMachine> = [];
  plans: Array<PlanDto> = [];

  viewInvoicePaymentStatusDataDto: ViewInvoicePaymentStatusDataDto;
  amountOutstanding: number;
  invoicePaymentStatus: InvoicePaymentStatusDto;
  invoiceDefinition: InvoiceDefinition;
  hasSavvyPayPayments = false;

  submitting = false;
  captureTokenized = {} as CaptureTokenizedPaymentComp;
  paymentSettings: PaymentSettings;
  paymentRequests: Array<RequestForPayment>;
  totalDepositRequests = 0;

  customerDto: Customer;
  viewOnly = false;
  postalCharges = 0;
  orgDto: OrgDto;
  constructor(
    private router: Router,
    private invoiceService: InvoiceService,
    private api: InvoicecompService,
    private translateService: TranslateService,
    private notify: FloSnackbarComponent,
    private dialog: MatDialog,
    private urlLookupService: UrlLookupService,
    private location: Location,
    private discountSettingsService: DiscountSettingsService,
    private invoicecompService: InvoicecompService,
    private paymentsApiComposite: PaymentcompService,
    private savvyPayCardMachineService: SavvyPayCardMachineService,
    private plansCompService: PlansCompService,
    private savvyPayPaymentCompApi: SavvyPayPaymentsCompService,
    private customerCompositeService: CustomerCompositeService,
    private customerService: CustomerService,
    private userCurrencyService: UserCurrencyService,
    private paymentSettingsApi: PaymentSettingsService,
    private orgCompService: OrgCompositeService,


    public deviceService: DeviceDetectorService,

    public dialogRef: MatDialogRef<ViewInvoiceV2Component>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) {
  }

  ngOnInit() {
    if (this.data.invoiceId) {
      this.invoiceId = this.data.invoiceId;
      this.contextIdDto = this.data.contextIdDto;
      if (!_.isUndefined(this.data.viewOnly)) {
        this.viewOnly = this.data.viewOnly;
      }
      if (!_.isUndefined(this.data.postalCharges)) {
        this.postalCharges = this.data.postalCharges;
      }
      console.log('loading invoice ' + this.invoiceId);
      this.getCurrencyCode();
      this.loadInvoice();
      this.loadDiscounts();
      this.loadPaymentSettings();
      this.loadCardMachines();
      this.loadOrg();
      if (!this.eventBus) {
        this.eventBus = new EventBusService();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.invoiceId) {
      console.log('loading invoice: ', this.invoiceId);
      console.log('changes.invoiceId is: ', changes.invoiceId);
      this.loadInvoice();
    }
  }

  public loadInvoiceById(invoiceId: string) {
    this.invoiceId = invoiceId;
    this.loadInvoice();
  }

  loadPaymentSettings() {
    this.paymentSettingsApi.loadPaymentSettings(this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    )//.pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        console.log('loaded payment settings ', response);
        this.paymentSettings = response;
      });

  }

  sendInvoice() {
    this.dialog.open(SendInvoiceDialogComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice,
        eventBus: this.eventBus
      },
      autoFocus: false,
      panelClass: 'helpwindow'
    });
  }

  loadOrg() {
    this.orgCompService.getCurrentOrgComp().subscribe(res => {
      if (res) {
        this.orgDto = res;
      }
    });
  }

  getCurrencyCode() {
    this.userCurrencyService.getDefaultCurrency(this.contextIdDto)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.currencyCode = res.org.currencyCode ? res.org.currencyCode : this.userCurrencyService.defaultCurrency;
      });
  }

  viewInvoice() {
    // const nativeWindow = this.injector.get(WindowRef).nativeWindow;
    // nativeWindow.open(url);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  backToList() {
    this.location.back();
  }

  loadDiscounts() {
    this.discountSettingsService
      .get(this.contextIdDto.contextId, this.contextIdDto.contextIdType)
      .subscribe(res => {
        this.discountSettings = res;
        if (this.discountSettings && this.discountSettings.discountItems) {
          this.discountSettings.discountItems.forEach(r => {
            this.discountItemsById[r.id] = r;
          });
        }
      });
  }


  gotoInvoice() {
    this.router.navigate(['/invoice/viewInvoice',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      this.invoiceId
    ]);
  }

  voidInvoice() {
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Void',
        message: 'Are you sure you want to void this invoice?'
      },
      height: 'auto',
      width: '350px',
      panelClass: 'helpwindow'
    });
    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.invoice.invoiceVoid = true;
        this.updateInvoice();
      }
    });
  }

  updateInvoice() {
    const req: UpdateInvoice = {} as UpdateInvoice;
    req.contextIdDto = this.contextIdDto;
    req.invoice = this.invoice;

    this.invoiceService.updateInvoice(req).subscribe(response => {
      this.invoice = response;
      this.isPaidFull = this.invoice.paidInFull;
      this.translateService.get('Updated Invoice')
        .pipe(takeUntil(this.destroy$))
        .subscribe(val => {
          this.notify.message = val;
          this.notify.open();
        });
    });
  }

  loadInvoicePaymentStatus() {
    if (this.invoiceId) {
      this.invoicecompService.loadInvoicePaymentStatus(
        this.invoiceId,
        this.contextIdDto.contextId, this.contextIdDto.contextIdType)
        .subscribe(response => {
          console.log('loaded invoice', response);
          this.invoicePaymentStatus = response.invoicePaymentStatusDto;
          this.amountOutstanding = response.invoicePaymentStatusDto.amountOutstanding;
          this.captureTokenized.amount = response.invoicePaymentStatusDto.amountOutstanding;
        });
    }
  }

  loadInvoiceState() {
    this.loadInvoice();
    this.loadInvoicePaymentStatus();
  }

  addPayment() {
    const dialogRef = this.dialog.open(AddPaymentModalComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice,
        totalDepositRequests: this.totalDepositRequests
      },
      autoFocus: false,
      panelClass: ['helpwindow']
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.loadInvoiceState();
          console.log('reloading invoice');
        }
      });
  }

  paidFull(type: string) {

    const payment = {} as Payment;

    console.log(this.totalDepositRequests);

    payment.amount = this.amountOutstanding - this.totalDepositRequests;
    payment.ownerId = this.contextIdDto.contextId;
    payment.invoiceId = this.invoice?.invoiceId;

    if (type === PaymentMethodEnum.Cash) {
      payment.paymentMethod = PaymentMethodEnum.Cash;
    } else if (type === PaymentMethodEnum.DirectDebit) {
      payment.paymentMethod = PaymentMethodEnum.DirectDebit;
    } else if (type === PaymentMethodEnum.BankTransfer) {
      payment.paymentMethod = PaymentMethodEnum.BankTransfer;
    } else if (type === PaymentMethodEnum.Savvypay) {
      payment.paymentMethod = PaymentMethodEnum.Savvypay;
    } else {
      payment.paymentMethod = PaymentMethodEnum.CardMachine;
    }

    const req = {} as RecordPaymentComp;
    req.contextIdDto = this.contextIdDto;
    req.payment = payment;

    this.paymentsApiComposite.recordPaymentComp(req)
      .subscribe(() => {
        this.loadInvoiceState();
        // this.invoiceUpdated.emit(response.invoice);

        this.translateService.get('Successfully added payment')
          .subscribe(val => {
            this.notify.message = val;
            this.notify.open();
          });
      }, () => {
        console.log('Error occurred while adding payment');
      });
  }

  loadCardMachines() {
    this.savvyPayCardMachineService.loadCardMachineByOwnerIdAndStatus(
      this.contextIdDto.contextId, this.contextIdDto.contextIdType, 'ACTIVE')
      .subscribe(res => {
        console.log('loaded card machines', res);
        this.cardMachines = res.cardMachines;
      });
  }

  showTerminalPayment() {
    console.log('32');
    console.log(this.totalDepositRequests);
    const dialogRef = this.dialog.open(CardMachinePaymentComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice,
        cardMachines: this.cardMachines,
        currencyCode: this.currencyCode,
        // entityInstanceId: this.appointment.id,
        locationId: this.invoice.locationId,
        totalDepositRequests: this.totalDepositRequests,
        amountOutstanding: this.invoice.total - this.invoicePaymentStatus.amountPaid
      },
      disableClose: true,
      height: 'auto',
      width: '500px',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe(() => {
      if (this.invoice) {
        this.invoiceService.loadInvoice(this.invoice.id, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(() => {
          // this.invoiceUpdated.emit(res);
          // this.getPayments();
        });
      }
    });

  }

  showSmsLink() {
    const dialogRef = this.dialog.open(SmsLinkPaymentComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice,
        totalDepositRequests: this.totalDepositRequests,
        currencyCode: this.currencyCode,
        customerDto: this.customerDto
      },
      disableClose: true,
      height: 'auto',
      width: '500px',
      panelClass: 'helpwindow'
    });

    dialogRef.afterClosed().subscribe(() => {
      // this.invoiceService.loadInvoice(this.invoice.id, this.contextIdDto.contextId, this.contextIdDto.contextIdType).subscribe(res => {
      //   this.invoiceUpdated.emit(res);
      //   // this.getPayments();
      // });
    });

  }

  showPaidPlans() {
    const entityInstanceId = null;
    const dialogRef = this.dialog.open(PlansPaymentComponent, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice,
        plans: this.plans,
        entityInstanceId
      },
      disableClose: true,
      height: 'auto',
      width: '500px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadInvoiceState();
      }
    });

  }

  loadPlans() {
    if (this.invoice.invoiceOwnerId && this.invoice.invoiceOwnerId.invoiceOwnerIdType === 'CUSTOMER_ID' && this.invoice.invoiceOwnerId.id) {
      this.plansCompService.loadActiveCustomerPlansComp(this.contextIdDto.contextId,
        this.contextIdDto.contextIdType, this.invoice.customerId.id).subscribe(res => {
          console.log('loaded plans', res);
          this.plans = res.plans;
        });
    }
  }

  goToSavvyPaySettings() {
    this.router.navigate(['/paymentSettings/paymentSettings',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ]);
  }

  takePayment() {
    this.submitting = true;
    this.captureTokenized.envId = this.contextIdDto.contextId;
    this.captureTokenized.amount = this.amountOutstanding;

    this.savvyPayPaymentCompApi.captureTokenizedPayment(this.captureTokenized).subscribe(res => {
      this.submitting = false;
      this.loadInvoiceState();

      if (res.serviceError && res.serviceError.message) {
        this.notify.message = res.serviceError.message;
        this.notify.open();
      } else if (res.resultCode === PaymentsResponse.ResultCodeEnum.Refused) {
        if (res.refusalReason) {
          this.notify.message = res.refusalReason;
        } else {
          this.notify.message = 'Payment has been refused by issuer bank.';
        }
        this.notify.open();
      } else {
        this.notify.message = 'Payment has been successfully captured.';
        this.notify.open();
      }
    });
  }

  chargeNoShow() {
    this.submitting = true;
    const merchantReference = this.viewInvoicePaymentStatusDataDto.paymentReferences[0];

    this.savvyPayPaymentCompApi.chargeNoShow(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      merchantReference).subscribe(res => {
        this.submitting = false;
        this.loadInvoiceState();
        if (res.serviceError && res.serviceError.message) {
          this.notify.message = res.serviceError.message;
          this.notify.open();
        }else if (res.resultCode === PaymentsResponse.ResultCodeEnum.Refused) {
          if (res.refusalReason) {
            this.notify.message = res.refusalReason;
          } else {
            this.notify.message = 'Payment has been refused by issuer bank.';
          }
          this.notify.open();
        } else {
          this.notify.message = 'No show charge has been successfully applied.';
          this.notify.open();
        }
      });
  }

  chargeLateCancellation() {
    this.submitting = true;
    const merchantReference = this.viewInvoicePaymentStatusDataDto.paymentReferences[0];

    this.savvyPayPaymentCompApi.chargeLateCancellation(
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType,
      merchantReference).subscribe(res => {
        this.submitting = false;
        this.loadInvoiceState();
        if (res.serviceError && res.serviceError.message) {
          this.notify.message = res.serviceError.message;
          this.notify.open();
        } else if (res.resultCode === PaymentsResponse.ResultCodeEnum.Refused) {
          if (res.refusalReason) {
            this.notify.message = res.refusalReason;
          } else {
            this.notify.message = 'Payment has been refused by issuer bank.';
          }
          this.notify.open();
        }  else {
          this.notify.message = 'Late cancellation charge has been successfully applied.';
          this.notify.open();
        }
      });
  }

  setHasSavvyPayments() {
    // Payment settings
    //   "savvyPaySettings":{
    //       "accountHolderCode":"611d5ef0b688b82719f833b8"
    //    },
    if (this.viewInvoicePaymentStatusDataDto?.paymentReferences
      && this.viewInvoicePaymentStatusDataDto?.paymentReferences.length > 0) {
      this.hasSavvyPayPayments = true;
      this.captureTokenized.merchantReference = this.viewInvoicePaymentStatusDataDto.paymentReferences[0];
    }
  }

  editInvoice() {
    const dialogRef = this.dialog.open(EditInvoiceV2Component, {
      data: {
        contextIdDto: this.contextIdDto,
        invoice: this.invoice
      },
      // disableClose: true,
      maxWidth: '800px',
      width: '800px',
      panelClass: ['scrollable-modal', 'invoicemodal'],
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadInvoiceState();
      }
    });
  }

  private getEnv() {
    if (GLOBAL.localhost === 'localhost') {
      return '&env=local';
    } else if (GLOBAL.localhost.startsWith('dev')) {
      return '&env=dev';
    }
    return '';
  }


  private loadInvoice() {
    if (this.invoiceId) {
      this.api.loadInvoiceForView(
        this.contextIdDto.contextId,
        this.contextIdDto.contextIdType,
        this.invoiceId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          response => {
            console.log('loaded invoice dto', response.invoiceDto);
            this.invoiceDto = response.invoiceDto;
            // @ts-ignore
            this.invoice = response.invoiceDto.invoice;
            this.isPaidFull = this.invoice.paidInFull;
            this.model = {} as Payment;
            this.model.amount = this.invoice.total;
            this.loadPlans();
            this.loadInvoicePaymentStatus();
          });
    } else {
      this.notify.message = 'Invoice Id does not exist';
      this.notify.open();
    }
  }

  goToPaymentSettings() {
    this.router.navigate(['/paymentSettings/paymentSettings',
      this.contextIdDto.contextId,
      this.contextIdDto.contextIdType
    ]);
  }

  getFullName() {
    return (this.invoiceDto?.invoice?.invoiceOwnerFirstName ? this.invoiceDto?.invoice?.invoiceOwnerFirstName : '')
      + ' '
      + (this.invoiceDto?.invoice?.invoiceOwnerLastName ? this.invoiceDto?.invoice?.invoiceOwnerLastName : '');
  }

}
